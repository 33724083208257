import React from 'react';
import {NavLink} from 'react-router-dom'
import flogo from './Footer Images/logo.png'

export default function App() {
  return (
    <footer className="bg-gray-800 bg-opacity-70 text-white dark:bg-neutral-600 dark:text-neutral-200">
      <div className="flex flex-col items-center justify-center border-b-2 border-neutral-200 p-6 dark:border-neutral-500 md:flex-row">
        <div className="mb-4 text-center md:mb-0 md:mr-12 md:text-left">
          <span className="text-2xl font-black">Get connected with us on social networks:</span>
        </div>
        {/* <!-- Social network icons container --> */}
        <div className="flex justify-center space-x-6">
          <NavLink className="mr-6 text-white dark:text-neutral-200">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="currentColor"
              viewBox="0 0 24 24">
              <path
                d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
            </svg>
          </NavLink>
          <NavLink className="mr-6 text-white dark:text-neutral-200">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="currentColor"
              viewBox="0 0 24 24">
              <path
                d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
            </svg>
          </NavLink>
          <NavLink className="mr-6 text-white dark:text-neutral-200">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="currentColor"
              viewBox="0 0 24 24">
              <path
                d="M7 11v2.4h3.97c-.16 1.029-1.2 3.02-3.97 3.02-2.39 0-4.34-1.979-4.34-4.42 0-2.44 1.95-4.42 4.34-4.42 1.36 0 2.27.58 2.79 1.08l1.9-1.83c-1.22-1.14-2.8-1.83-4.69-1.83-3.87 0-7 3.13-7 7s3.13 7 7 7c4.04 0 6.721-2.84 6.721-6.84 0-.46-.051-.81-.111-1.16h-6.61zm0 0 17 2h-3v3h-2v-3h-3v-2h3v-3h2v3h3v2z"
                fillRule="evenodd"
                clipRule="evenodd" />
            </svg>
          </NavLink>
          <NavLink className="mr-6 text-white dark:text-neutral-200">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="currentColor"
              viewBox="0 0 24 24">
              <path
                d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
            </svg>
          </NavLink>
          <NavLink className="mr-6 text-white dark:text-neutral-200">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="currentColor"
              viewBox="0 0 24 24">
              <path
                d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
            </svg>
          </NavLink>
          <NavLink className="text-white dark:text-neutral-200">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="currentColor"
              viewBox="0 0 24 24">
              <path
                d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />
            </svg>
          </NavLink>
        </div>
      </div>

      <div className="mx-4 py-10 text-center md:mx-12 md:text-left">
        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-4">
          {/* Tailwind Elements section */}
          <div className="text-center md:text-left">
            <h6 className="mb-4 flex items-center justify-center font-semibold uppercase md:justify-start">
              <img src={flogo} alt="SunPay Logo" className="w-4/6 h-4/6 rounded-xl" />
            </h6>
            <p>
              SunPay includes virtually everything you need to expand upon your current service offerings or start your own Multi-services business.
            </p>
          </div>

         {/* Services section */}
          <div className="text-center md:text-left md:ml-12 lg:ml-24">
            <h6 className="mb-4 flex justify-center font-semibold text-xl uppercase md:justify-start">
              Quick Links
            </h6>
            {['Home', 'About us', 'Services', 'Contact us'].map((link, index) => {
              if (link === 'Home') {
                return (
                  <p key={index} className="mb-4">
                    <NavLink to="/" className="text-white dark:text-neutral-200">
                      {link}
                    </NavLink>
                  </p>
                );
              } else if (link === 'About us') {
                return (
                  <p key={index} className="mb-4">
                    <NavLink to={`/${link.toLowerCase().replace(' ', '-')}`} className="text-white dark:text-neutral-200">
                      <i className="fas fa-info-circle"></i> {link}
                    </NavLink>
                  </p>
                );
              } else if (link === 'Services') {
                return (
                  <p key={index} className="mb-4">
                    <NavLink to={`/${link.toLowerCase().replace(' ', '-')}`} className="text-white dark:text-neutral-200">
                      {link}
                    </NavLink>
                  </p>
                );
              } else if (link === 'Contact us') {
                return (
                  <p key={index} className="mb-4">
                    <NavLink to={`/${link.toLowerCase().replace(' ', '-')}`} className="text-white dark:text-neutral-200">
                      {link} 
                    </NavLink>
                  </p>
                );
              } else {
                return (
                  <p key={index} className="mb-4">
                    <NavLink to={`/${link.toLowerCase().replace(' ', '-')}`} className="text-white dark:text-neutral-200">
                      {link}
                    </NavLink>
                  </p>
                );
              }
            })}
          </div>


          {/* Useful links section */}
          <div className="text-center md:text-left md:ml-6 lg:ml-12">
            <h6 className="mb-4 text-xl flex justify-center font-semibold uppercase md:justify-start">
              Legals
            </h6>
            {['Privacy Policy', 'Terms & Conditions', 'Refund & Cancellation', 'Help'].map((link, index) => (
              <p key={index} className="mb-4">
                <NavLink className="text-white dark:text-neutral-200">
                  {link}
                </NavLink>
              </p>
            ))}
          </div>

          {/* Contact section */}
          <div className="text-center md:text-left">
            <h6 className="mb-4 text-xl flex justify-center font-semibold uppercase md:justify-start">
              Get In Touch With Us.
            </h6>
            <p className="mb-4 flex items-center justify-center md:justify-start">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="mr-3 h-8 w-8">
                <path d="M11.47 3.84a.75.75 0 011.06 0l8.69 8.69a.75.75 0 101.06-1.06l-8.689-8.69a2.25 2.25 0 00-3.182 0l-8.69 8.69a.75.75 0 001.061 1.06l8.69-8.69z" />
                <path d="M12 5.432l8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 01-.75-.75v-4.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75V21a.75.75 0 01-.75.75H5.625a1.875 1.875 0 01-1.875-1.875v-6.198a2.29 2.29 0 00.091-.086L12 5.43z" />
              </svg>
              772-774, 2nd Floor Off. No.2 Kundewalan Ajmeri Gate New Delhi
            </p>
            <p className="mb-4 flex items-center justify-center md:justify-start">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="mr-3 h-5 w-5">
                <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                <path d="M22.5 6.75v-.002a3 3 0 00-3-3H4.5a3 3 0 00-3 3v.002L12 12.75l10.5-6z" />
              </svg>
              support@sunpay.in
            </p>
            <p className="mb-4 flex items-center justify-center md:justify-start">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="mr-3 h-5 w-5">
                <path fillRule="evenodd" d="M4.036 3.636a2.25 2.25 0 013.182-.153l2.66 2.343a2.25 2.25 0 01.72 2.145c-.194.87-.117 1.913.344 3.108.454 1.18.987 2.1 1.699 2.813.758.759 1.681 1.274 2.92 1.736 1.197.457 2.239.54 3.108.345a2.25 2.25 0 012.145.72l2.343 2.66a2.25 2.25 0 01-.152 3.182l-1.645 1.644a2.25 2.25 0 01-2.37.526c-2.702-1.043-5.247-3.102-7.684-5.538-2.437-2.438-4.495-4.982-5.538-7.684a2.25 2.25 0 01.526-2.37l1.644-1.645z" clipRule="evenodd" />
              </svg>
              +91 9403891008
            </p>
          </div>
        </div>
      </div>
      <div className="text-center p-6 bg-gray-800 bg-opacity-70 text-white dark:bg-neutral-600 dark:text-neutral-200">
        <span>© 2023 Copyright:</span>
        <NavLink to="/" className="font-semibold">
          Sunpay
        </NavLink>
      </div>
    </footer>
  );
}